body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #000;
}

.color-explanation--relative {
  position: relative !important;
  left: 0 !important;
}

.color-explanation {
  position: absolute;
  left: 275px;

  height: 93px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;

  div {
    margin-right: 24px;

    p {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;
    }
  }
}

.fc {
  padding: 28px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.event--disabled {
  cursor: not-allowed;
  filter: saturate(0%);
}

.event--enabled {
  cursor: pointer;
}

.event--partially-booked {
  cursor: pointer;
  background-color: #d8c237;
  border: 1px solid #d8c237;
}

.event--annulled {
  cursor: not-allowed;
  background: repeating-linear-gradient(
                  45deg,
                  #a0a0a0,
                  #A0A0A0 10px,
                  #7c7c7c 10px,
                  #7c7c7c 20px
  );
  border: 1px solid #7c7c7c;
}

.event--fully-booked {
  cursor: not-allowed;
  background-color: #d83737;
  border: 1px solid #d83737;
}

.box.box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;

  &.box--grey {
    background-color: #7c7c7c;
  }

  &.box--grey-striped {
    background: repeating-linear-gradient(
                    45deg,
                    #a0a0a0,
                    #A0A0A0 10px,
                    #7c7c7c 10px,
                    #7c7c7c 20px
    );
  }

  &.box--red {
    background-color: #d83737;
  }

  &.box--yellow {
    background-color: #d8c237;
  }

  &.box--blue {
    background-color: #3788d8;
  }
}

.fc .fc-button-primary {
  background: #046e65;
  border: #046e65;
}

.fc-h-event {
  background-color: #2196f3;
  border: #2196f3;
}