body {
  color: #000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.color-explanation--relative {
  position: relative !important;
  left: 0 !important;
}

.color-explanation {
  height: 93px;
  flex-flow: row;
  place-content: stretch flex-start;
  align-items: center;
  display: flex;
  position: absolute;
  left: 275px;
}

.color-explanation div {
  margin-right: 24px;
}

.color-explanation div p {
  flex-flow: row;
  place-content: stretch flex-start;
  align-items: center;
  display: flex;
}

.fc {
  width: 100vw;
  height: 100vh;
  padding: 28px;
  overflow: hidden;
}

.event--disabled {
  cursor: not-allowed;
  filter: saturate(0%);
}

.event--enabled {
  cursor: pointer;
}

.event--partially-booked {
  cursor: pointer;
  background-color: #d8c237;
  border: 1px solid #d8c237;
}

.event--annulled {
  cursor: not-allowed;
  background: repeating-linear-gradient(45deg, #a0a0a0, #a0a0a0 10px, #7c7c7c 10px 20px);
  border: 1px solid #7c7c7c;
}

.event--fully-booked {
  cursor: not-allowed;
  background-color: #d83737;
  border: 1px solid #d83737;
}

.box.box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.box.box.box--grey {
  background-color: #7c7c7c;
}

.box.box.box--grey-striped {
  background: repeating-linear-gradient(45deg, #a0a0a0, #a0a0a0 10px, #7c7c7c 10px 20px);
}

.box.box.box--red {
  background-color: #d83737;
}

.box.box.box--yellow {
  background-color: #d8c237;
}

.box.box.box--blue {
  background-color: #3788d8;
}

.fc .fc-button-primary {
  background: #046e65;
  border: #046e65;
}

.fc-h-event {
  background-color: #2196f3;
  border: #2196f3;
}

.trip-info {
  flex-flow: column;
  place-content: stretch space-between;
  align-items: stretch;
  display: flex;
  overflow-x: hidden;
}

.trip-info--metadata {
  width: calc(100% + 48px);
  background: #f9f9f9;
  border-top: 4px solid #046e65;
  border-bottom: 4px solid #046e65;
  border-left: 0;
  border-radius: 0;
  margin-left: -24px;
  padding: 16px;
  position: relative;
}

.trip-info--icon {
  position: absolute;
  top: calc(50% - 36px);
  right: 16px;
  fill: #b9b9b9 !important;
  height: 72px !important;
  width: 72px !important;
}

/*# sourceMappingURL=index.144827ee.css.map */
