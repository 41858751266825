.trip-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  overflow-x: hidden;

  &--metadata {
    background: #f9f9f9;
    border-top: 4px solid #046e65;
    border-bottom: 4px solid #046e65;
    border-radius: 0;
    padding: 16px;
    position: relative;
    margin-left: -24px;
    border-left: 0;
    width: calc(100% + 48px);
  }

  &--icon {
    position: absolute;
    top: calc(50% - 36px);
    right: 16px;

    fill: #b9b9b9 !important;
    height: 72px !important;
    width:72px !important;
  }
}